/* In your src/index.css or App.css file */

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Comment out or remove these styles if you're using Tailwind to center your content
#root {
  display: flex;
  align-items: center;
  height: 100vh;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers